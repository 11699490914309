<template>
  <div v-if="getItemError">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Ошибка при получении данных роли
      </h4>
      <div class="alert-body">
        Не найдено роли с этим идентификатором. Можете перейти в
        <b-link
          class="alert-link"
          :to="{ name: 'roles'}"
        >
          Список ролей
        </b-link>
        и выбрать другую.
      </div>
    </b-alert>
  </div>
  <b-overlay
    v-else
    :show="loading"
    spinner-variant="primary"
  >
    <b-card>
      <b-row>
        <b-col>
          <h4>
            {{ item.name }}
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <table class="mt-1 w-100">
            <p v-if="item.company_id === null">
              <small class="text-info">
                Предзаданная роль (Невозможно отредактировать)
              </small>
            </p>
            <p class="mb-0">
              {{ item.desc }}
            </p>
          </table>
        </b-col>
      </b-row>
      <b-row
        v-if="item.company_id !== null && $store.getters['workingMode/checkPermission']('manage_roles')"
        class="mt-2"
      >
        <b-col>
          <div class="d-flex flex-wrap">
            <b-button
              v-if="item.id"
              :to="{ name: 'roles.edit', params: { id: item.id } }"
              variant="primary"
            >
              Изменить
            </b-button>
            <b-button
              variant="outline-danger"
              class="ml-1"
              @click="deleteItem(item)"
            >
              Удалить
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-card
        no-body
        class="border mt-2"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="LockIcon"
              size="18"
            />
            <span class="align-middle ml-50">Разрешения</span>
          </b-card-title>
        </b-card-header>
        <b-table-simple
          :striped="false"
          :bordered="false"
          :borderless="false"
          :outlined="false"
          :small="false"
          :hover="false"
          :dark="false"
          :fixed="false"
          :responsive="true"
          class="m-0"
        >
          <b-tbody
            v-for="permissionCategory in groupedPermissions"
            :key="'c' + permissionCategory.id"
          >
            <b-tr>
              <b-th
                colspan="2"
                class="bg-light"
              >
                {{ permissionCategory.name }}
              </b-th>
            </b-tr>
            <b-tr
              v-for="permission in permissionCategory.permissions"
              :key="'p' + permission.id"
            >
              <b-td>
                <!--                <b-form-checkbox-->
                <!--                  v-model="item.permissions[permission.code]"-->
                <!--                  :value="1"-->
                <!--                  :unchecked-value="0"-->
                <!--                  class="pl-0"-->
                <!--                  disabled-->
                <!--                />-->
                <feather-icon
                  :icon="item.permissions[permission.code] ? 'CheckIcon': 'MinusIcon'"
                  size="20"
                  :class="{
                    'text-success': item.permissions[permission.code],
                    'text-secondary': !item.permissions[permission.code],
                  }"
                />
              </b-td>
              <b-td>
                <div class="">
                  {{ permission.name }}
                </div>
                <div class="small text-secondary">
                  {{ permission.desc }}
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BOverlay, BButton, BAlert, BLink, BBadge, BTabs, BTab,
  BFormGroup, BFormInput, BFormTextarea, BCardHeader, BCardTitle, BTable, BFormCheckbox,
  BTableSimple, BTbody, BThead, BTr, BTd, BTh,
} from 'bootstrap-vue'
import {
  computed, getCurrentInstance, onMounted, ref,
} from 'vue'
import get from 'lodash/get'
import { getRole, deleteRole } from '@/services/main-api/business/roles'
import { getPermissions } from '@/services/main-api/business/permissions/permissions'
import { getPermissionCategories as getPermissionCategoriesApi } from '@/services/main-api/business/permissions/categories'
import useCrudShow from '@/composables/useCrudShow'

export default {
  name: 'ShowItemView',
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BButton,
    BAlert,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCardHeader,
    BCardTitle,
    BTable,
    BFormCheckbox,
    BTableSimple,
    BTbody,
    BThead,
    BTr,
    BTd,
    BTh,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy
    const getItemError = ref(false)
    const permissions = ref([])
    const permissionCategories = ref([])

    const getAllPermissions = async () => {
      try {
        const res = await getPermissions({
          perPage: 1000,
          page: 1,
        })
        permissions.value = res.data.data
      } catch (e) {
        permissions.value = []
      }
    }

    const getPermissionCategories = async () => {
      try {
        const res = await getPermissionCategoriesApi({
          perPage: 1000,
          page: 1,
        })
        permissionCategories.value = res.data.data
      } catch (e) {
        permissionCategories.value = []
      }
    }

    const getItem = async () => {
      try {
        const res = await getRole(props.id)
        instance.item = res.data.data
      } catch (e) {
        instance.item = {}
        instance.getItemError = true
      }
    }

    const groupedPermissions = computed(() => permissionCategories.value.map(c => ({
      ...c,
      permissions: permissions.value.filter(p => p.permission_category_id === c.id),
    })))

    const {
      item,
      loading,
      deleteItem,
    } = useCrudShow({
      item: {
        id: null,
        name: '',
        desc: '',
        permissions: {},
      },
      getItem,
      deleteEntity: i => deleteRole(i.id),
      getQuestionTextBeforeDeletion: i => `Роль ${i.name} будет удалена`,
      getTextAfterDeletion: () => 'Роль была удалена.',
      runAfterDeletion: () => {
        instance.$router.push({ name: 'roles' })
      },
      getMoreData: async () => {
        await Promise.all([
          getAllPermissions(),
          getPermissionCategories(),
        ])
      },
    })

    onMounted(() => {
      if (!instance.$store.getters['workingMode/mode']) {
        instance.$router.push({ name: 'dashboard' })
      }
    })

    return {
      item,
      loading,

      getItemError,
      deleteItem,

      permissions,
      permissionCategories,
      groupedPermissions,

      get,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
